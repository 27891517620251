import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Hero from '@components/Hero';
import SectionTitle from '@components/SectionTitle';
import {BLUE, ORANGE} from '@styles/colors';

export default ({data}: Object) => {
  const {title, meta} = data.site.siteMetadata;
  const {fluid: fluidHeroImage} = data.allFile.edges[0].node.childImageSharp;
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={meta} />
      </Helmet>
      <Hero
        fluid={fluidHeroImage}
        background={'rgb(255, 255, 255, 0.1)'}
        imgStyle={{width: 'auto', height: 'auto', position: 'relative'}}
        imageContainerStyle={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionTitle
          headline="Whoops!"
          copy="An unexpected error has occurred. Please try refreshing your browser or contact us if the problem persists."
          dividerColor={ORANGE}
          alignItems="center"
          textAlign="center"
          copyColor={BLUE}
          width="40%"
          position="absolute"
        />
      </Hero>
    </Fragment>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        meta
      }
    }
    allFile(
      filter: {
        sourceInstanceName: {eq: "data"}
        ext: {eq: ".png"}
        name: {eq: "500"}
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;
